const headerTrigger = document.querySelector(".l-header__button");
const headerTarget = document.querySelector(".l-header__inner");
const headerItems = document.querySelectorAll(".l-header__navItem");
const headerWrap = document.querySelector(".l-header__wrap");
const headerLogo = document.querySelector(".l-header__logo");
const body = document.querySelector("html")
const wh = window.innerHeight;
const img = document.querySelector(".l-header__logo img");
// const src = img.src
const topPos_logo = img.getBoundingClientRect().top + window.pageYOffset;

headerTrigger.addEventListener('click', function(){
    headerTarget.classList.toggle("is-active");
    body.classList.toggle("no-scroll");
    headerTrigger.classList.toggle("is-active");
    headerWrap.classList.toggle("is-active");
    if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
        if(!headerLogo.classList.contains("is-active")){
            headerLogo.classList.toggle("is-black")  
        }
        if(headerLogo.classList.contains("is-black")){
            img.setAttribute('src', '/assets/img/top/logo_header_black.svg')
        }
        else{
            img.setAttribute('src', '/assets/img/top/logo_header.svg')
        }
    } 
});


// 下層ページヘッダー

const top = document.getElementById("top");
const news = document.getElementById("news");
console.log(news)

if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
    const logo = document.querySelector(".l-header__logo");
    const img = document.querySelector(".l-header__logo img");

if(news == null && top == null){
    const kv_h = document.querySelector(".c-page__kv").clientHeight;
window.addEventListener('scroll', (event) => {
    const sh = window.pageYOffset;
    if(sh > kv_h){
        logo.classList.add("is-black");
        logo.classList.add("is-active");
        img.setAttribute('src', '/assets/img/top/logo_header_black.svg')
        
    }
    else{
        logo.classList.remove("is-black");
        logo.classList.remove("is-active");
        img.setAttribute('src', '/assets/img/top/logo_header.svg')
    }
});
}
else if(news != null && top == null){
    logo.classList.add("is-black");
    logo.classList.add("is-active");
    img.setAttribute('src', '/assets/img/top/logo_header_black.svg')
};
};



for(let i = 0; i < headerItems.length; i++){
    headerItems[i].addEventListener("click", function(){
        headerTarget.classList.remove("is-active");
        body.classList.remove("no-scroll");
        headerTrigger.classList.remove("is-active");
        headerWrap.classList.remove("is-active");
        headerLogo.classList.remove("is-black");
    })
}
